const routes = require('../../../../common/routes2');
import savePost  from '../../../common/js/advanced.post'
import Routing from '../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';


export default function(store,pricingID)
{
    Routing.setRoutingData(routes);
    let route =  Routing.generate('dynamic_shop_bag', {lang:'en',store: store });
    let data = {'action':'preview-product',pricingID:pricingID};
    savePost(route,data);
};