/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
// any CSS you import will output into a single css file (app.css in this case)
import './app/common/fonts.css';
import './styles/theme.css';
import './styles/app.css';
import './styles/footer.css';
import './styles/pages.css';
import './styles/search.css';
import './styles/sidepanel.navbar.css';
import './styles/shop.css';
import './app/shop/home/css/products.gallerie.css';
import './app/shop/home/css/search.panel.css';
import './styles/try.it.css';
import './app/shop/counter/css/counter.css';
import './app/shop/home/css/product.card.colors.css';
import './styles/product.details.css';
import './styles/store.discounts.css';
import './styles/animated.product.card.css';

import $ from 'jquery';

import Swal from 'sweetalert2';

global.$ = $ ;
global.Swal = Swal;

import 'lazysizes';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/util';
//import 'bootstrap/dist/js/bootstrap.min'

//import '@fortawesome/fontawesome-free';
import "@fortawesome/fontawesome-free/js/all.js";
import "@fortawesome/fontawesome-free/css/all.css";

import savePost  from './app/common/js/advanced.post'
import toggleWishList  from './app/shop/home/js/toggleWishList'
import previewProduct  from './app/shop/home/js/previewProduct'

import Routing from '../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
const routes = require('./common/routes2');



Routing.setRoutingData(routes);

function searchResults()
{
    $('#GlobalSearchResults').fadeOut('fast',function () {
        $('#GlobalLoadingResults').fadeIn();
        let store =  (typeof CurrentStoreCode !==  "undefined") ? CurrentStoreCode : 'retail';
        let route =  Routing.generate('dynamic_shop_search', {lang:'en',store: store });
        let searchQuery = $('#global-search-input').val();
        let sortBy = $('#panel-sorter-field').val();
        let sortDirection = $('#panel-sorter-direction').val();
        let searchCategories =  [];
        $('input:checkbox[name=selectedCategories]:checked').each(function() {
            searchCategories.push($(this).val());
        });
        savePost(route,{'action':'search-products',searchQuery:searchQuery,searchCategories:searchCategories,sortBy:sortBy,sortDirection:sortDirection},showResults);
    });
}


function cancelSearchPanel(){
    $('#global-search-panel').fadeOut();
}

function openSearchPanel(){
   //console.log('openSearchPanel');
    $('#global-search-panel').fadeIn();
}

function showResults(data)
{
   //console.log(` showResults`);
   //console.log(data);

    if(data != null && typeof data.status !== "undefined" && typeof data.callback !== "undefined" )
    {
        let dataDong = data.callback ;
        $('#GlobalSearchResults').html(dataDong);
        $('#GlobalLoadingResults').fadeOut('fast',function () {
            $('#GlobalSearchResults').fadeIn();
        });
    }


}




$(function(){




    $(".nav-tabs a").click(function(){
        $(this).tab('show');
    });

    $(".search-panel a.close-global-search").click(function(){
        cancelSearchPanel() ;
    });
    $(".search-panel a.open-global-search").click(function(){
        openSearchPanel() ;
    });

    $("#search-global-btn").click(function(){
        searchResults();
    });


    $("#panel-sorter-field,#panel-sorter-direction").change(function(){
        if($('#global-search-input').val().length > 0)
            searchResults();
    });

    $(".toggle-global-filters").click(function(){
        $('.toggle-global-filters').toggleClass('opened');
        $('#show-categories-list').slideToggle('fast');
    });


    var input = document.getElementById("global-search-input");
    input.addEventListener("keyup", function(event) {
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
            event.preventDefault();
            searchResults();
        }
    });

    let subToggler = $('.submenu-toggle');
    if(subToggler.length > 0)
    {
        subToggler.click(function(e){
           //console.log('nav menu change');
            if(!$(this).hasClass('open')) {
                subToggler.removeClass('open');
                $('.has-sub-items .submenu').hide();
            }
            $(this).toggleClass('open').next().toggle();
            if($(this).hasClass('open')){
                $(this).find('.submenu-link').attr("aria-expanded",true);
            }else{
                $(this).find('.submenu-link').attr("aria-expanded",false);
            }
        });
    }




    $(document).on('click','.btn-preview',function () {
        let pricing_encodedID = $(this).data('pricing-id');
        let storeCode = $(this).data('store-code');
        previewProduct(storeCode,pricing_encodedID);
    });



    $(document).on('click','.btn-wishlist',function () {
        let product_encodedID = $(this).data('product-id');
        let storeCode = $(this).data('store-code');
        toggleWishList(storeCode,product_encodedID);
    });

    //startCounter("discount-counter");


    $('a[href*=\\#]').on('click', function(event){
        event.preventDefault();
        // TODO fix error
        $('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);
    });
});



$(document).ready(function(e)
{

    $('[data-toggle="tooltip"]').tooltip();

    $('#loading-page').fadeOut('slow');









});