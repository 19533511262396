const routes = require('../../../../common/routes2');
import savePost  from '../../../common/js/advanced.post'
import Routing from '../../../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import $ from 'jquery';

function afterToggle(data)
{
    console.log('afterWishListToggle');
    console.log(data);
    if(data != null && typeof data.wishedProduct !=="undefined" && typeof data.removed !=="undefined"){
        let wishedProductID = data.wishedProduct ;
        let isRemoved = data.removed ;
        console.log('valid data',wishedProductID,isRemoved);

        let $wishedProduct = $('.product-wished-'+wishedProductID);

        if(isRemoved){
            $wishedProduct.attr('title','Add to wish list').removeClass('product-wished');
        }
        else {
            $wishedProduct.attr('title','Remove from wish list').addClass('product-wished');
        }

        if(typeof data.html !=="undefined"){
            $wishedProduct.html(data.html);
        }
    }
}



export default function(store,productID)
{
    Routing.setRoutingData(routes);
    let route =  Routing.generate('dynamic_shop_bag', {lang:'en',store: store });
    console.log('WishListToggle(code,encodedID)',store,productID);
    let data = {'action':'toggle-wish-list',encodedID:productID,store:store};
    savePost(route,data,afterToggle);
};